@import "colors";

$btn-font-weight:                normal !default;

$btn-default-color:              $text-color !default;
$btn-default-bg:                 $gray-16 !default;
$btn-default-border:             darken($btn-default-bg, 5%) !default;

$btn-secondary-color:            $text-color !default;
$btn-secondary-bg:               $gray-10 !default;
$btn-secondary-border:           darken($btn-default-bg, 10%) !default;

$btn-primary-color:              $gray-0 !default;
$btn-primary-bg:                 $blue-80 !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              $gray-0 !default;
$btn-success-bg:                 $green-60 !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 $gray-0 !default;
$btn-info-bg:                    $blue-40 !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              $gray-0 !default;
$btn-warning-bg:                 $orange-40 !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               $gray-0 !default;
$btn-danger-bg:                  $red-70 !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        $gray-35 !default;
$btn-disabled-color:             $gray-0 !default;
