$gray-0:       #fff;
$gray-3:       #eee;
$gray-5:       #f3f3f3;
$gray-10:      #e5e5e5;
$gray-15:      #f6f6f6;
$gray-16:      #fafafa;
$gray-18:      #cbd5dd;
$gray-20:      #e9e9e9;
$gray-25:      #ccc;
$gray-30:      #a5a5a5;
$gray-35:      #999;
$gray-40:      #767676;
$gray-100:     #000;

$green-5:      #f3faf3;
$green-10:     #f2fcf9;
$green-20:     #ddf7eb;
$green-40:     #62E193;
$green-50:     #42B392;
$green-60:     #23AE89;
$green-70:     #229173;
$green-80:     #2C7661;

$red-5:        #fdf7f7;
$red-10:       #fdefee;
$red-20:       #fad7db;
$red-60:       #FC6B51;
$red-70:       #e94b3b;
$red-80:       #b13d31;

$orange-5:     #fcf8f2;
$orange-10:    floralwhite;
$orange-20:    #ffebd7;
$orange-40:    #FFB61C;
$orange-50:    #D8AB00;
$orange-60:    #FDA64D;
$orange-70:    #F98E33;
$orange-80:    #af7f18;

$blue-40:      #2EC1CC;
$blue-50:      #5aadfc;
$blue-60:      #449dd5;
$blue-80:      #2C3E50;

$violet-60:    #6A55C2;
$rose-60:      #CD00EA;

$text-color:   $gray-40 !default;
