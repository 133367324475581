@import "~theme/variables/butons";
@import "~theme/variables/colors";
@import "~theme/variables/modal";
@import "~theme/variables/grid";
@import "~theme/variables/layout";
@import "~theme/variables/flash";
@import "~theme/variables/callout";
@import "~theme/variables/blockquote";
@import "~theme/variables/alert";
@import "~theme/variables/toast";
@import "~theme/variables/table";
@import "~theme/variables/forms";

//** Toolbar.
$toolbar-height: 50px;

//** Footer.
$footer-height: 150px;

$min-height: 300px;
