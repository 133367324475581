app-sidebar {
  .mat-list-base {
    .mat-list-item {
      .mat-list-item-content {
        padding: 0;
      }

      &.accordion-item {
        .mat-list-item-content {
          align-items: flex-start;
        }
      }
    }
  }

  mat-expansion-panel {
    box-shadow: none!important;

    .mat-expansion-panel-header {
      height: 50px;

      &.mat-expanded {
        height: 50px;
      }
    }

    .mat-expansion-panel-body {
      height: 50px;
      padding: 0;
    }
  }
}
