@import "~theme/variables";

a {
  text-decoration: none;
  color: $gray-100;

  &:hover {
    text-decoration: underline;
    color: $gray-100;
  }

  &.terms {
    text-decoration: revert;
    color: $gray-40;
    font-size: 16px;
  }
}
