@import "~theme/variables";

.mat-snack-bar-container {
  &.success {
    background-color: $toast-success;
  }

  &.info {
    background-color: $toast-info;
  }

  &.warning {
    background-color: $toast-warning;
  }

  &.error {
    background-color: $toast-error;
  }
}
