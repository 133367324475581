@import "~theme/variables";

form {
  .form-group {
    margin-bottom: 15px;

    .input-group {
      position: relative;
      display: flex;
      border-collapse: separate;
      flex-flow: wrap;

      .icon {
        height: 46px;
        width: 46px;
        background-color: $input-icon-bg;
        border: 1px solid $input-icon-border;
        padding: 11px;
        border-radius: 2px 0 0 2px;
        border-right: 0;
        box-sizing: border-box;

        mat-icon {
          color: $text-color;
          text-align: center;
        }
      }

      input {
        outline: none;
        font-size: 14px;
        border: 1px solid $input-border;
        border-radius: 0 2px 2px 0;
        padding: 10px;
        flex-grow: 3;
        color: $gray-40;

        &.ng-dirty {
          &.ng-invalid {
            border-color: $input-invalid-border;
          }

          &.ng-valid {
            border-color: $input-valid-border;
          }
        }
      }

      input::placeholder {
        font-weight: 100;
        opacity: .6;
      }

      input:disabled,
      input:read-only {
        pointer-events: none;
        background-color: $gray-3;
      }

      select {
        outline: none;
        width: 100%;
        height: 34px;
        display: block;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.4;
        color: $text-color;
        background-color: $select-bg;
        background-image: none;
        border: 1px solid $select-border;
        border-radius: 2px;
      }
    }
  }

  .btn-primary {
    box-shadow: none;
    width: 100%;
    height: 46px;
    font-size: 18px;

    &.mat-button-disabled {
      color: $btn-primary-color!important;
      background-color: $btn-primary-bg!important;
      opacity: .65;
    }
  }
}

app-error-feedback {
  display: block;
  width: 100%;
  text-align: center;
  color: $feedback-error;
}

.additional-links {
  text-align: center;
}

.double {
  .mat-grid-tile-content {
    flex-wrap: wrap;
  }
}
