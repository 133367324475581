@use '@angular/material' as mat;

@include mat.core();

@import "theme/variables";
@import "theme/components";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/material-icon/MaterialIcons-Regular.eot');
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('assets/fonts/material-icon/MaterialIcons-Regular.woff2') format('woff2'),
  url('assets/fonts/material-icon/MaterialIcons-Regular.woff') format('woff'),
  url('assets/fonts/material-icon/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

$font-family-base: 'Lato', Helvetica, Arial, sans-serif !default;
$ocp-primary: mat.define-palette(mat.$red-palette);
$ocp-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);
$ocp-warn: mat.define-palette(mat.$red-palette);
$my-typo-config: mat.define-typography-config(
        $font-family: $font-family-base,
        $headline: mat.define-typography-level(36px, 1.4, 100),
        $title: mat.define-typography-level(30px, 1.1, 100),
        $subheading-2: mat.define-typography-level(24px, 1.1, 100),
        $subheading-1: mat.define-typography-level(18px, 1.1, 100),
);

$ocp-theme: mat.define-light-theme((
        color: (
                primary: $ocp-primary,
                accent: $ocp-accent,
                warn: $ocp-warn,
        ),
));

@include mat.core($my-typo-config);
@include mat.all-component-themes($ocp-theme);

/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
  overflow: hidden;

  body {
    margin: 0;
    font-family: $font-family-base;
    height: 100%
  }
}

mat-dialog-container,
mat-card {
  min-width: $min-height;
}
