@import "~theme/variables";

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 2px;
  text-align: center;

  &.alert-success {
    background-color: $alert-success-bg;
    border-color: $alert-success-border;
    color: $alert-success-text;
  }
}
