@import "~theme/variables";

.mat-table {
  width: 100%;

  .mat-header-cell {
    font-size: 14px;
  }

  .mat-header-row {
    th {
      font-weight: bold;
    }
  }

  .mat-row {
    &:nth-child(odd) {
      background: $table-row-bg;
    }

    td {
      color: $text-color;

      .mat-button-base {
        span {
          color: $text-color;
        }
      }

      &.text-danger {
        color: $table-text-danger;
      }

      &.text-success {
        color: $table-text-success;
      }
    }
  }
}

.table-scroll {
  overflow-x: scroll;
  padding: 10px;

  table {
    min-width: 1000px;
  }
}


