@import "~theme/variables";

button {
  &.btn {
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: 2px;
    min-width: 120px;
    line-height: 32px;

    &.btn-default {
      color: $btn-default-color;
      background-color: $btn-default-bg;
      border-color: $btn-default-border;
    }

    &.btn-secondary {
      color: $btn-secondary-color;
      background-color: $btn-secondary-bg;
      border-color: $btn-secondary-border;
    }

    &.btn-success {
      color: $btn-success-color;
      background-color: $btn-success-bg;
      border-color: $btn-success-border;
    }

    &.btn-primary {
      color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;
    }

    &.btn-warning {
      color: $btn-warning-color;
      background-color: $btn-warning-bg;
      border-color: $btn-warning-border;
    }

    &.btn-danger {
      color: $btn-danger-color;
      background-color: $btn-danger-bg;
      border-color: $btn-danger-border;
    }

    &.no-shadow {
      box-shadow: none;
    }

    mat-icon {
      font-size: 16px;
      line-height: 24px;
      margin-left: -8px;
    }
  }
}

.mat-button-disabled {
  opacity: 0.6;
  color: $btn-disabled-color!important;
}

